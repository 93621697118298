<!--
 * @Description: 我的收藏
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-12-03 22:51:41
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/thread/collect/index.vue
-->
<template>
  <div class="app-wrapper">
    <div class="wrapper-view">
      <div>
        <div class="wrapper-title">我的收藏</div>
        <el-table
          :data="tableData"
          stripe
          max-height="580"
          style="width: 100%; margin-top: 21px"
          v-loading="isLoading"
        >
          <el-table-column prop="account" label="客户名称" align="center">
            <template slot-scope="{ row: { user_detail } }">
              <div class="custom-view">
                <div class="custom-img">
                  <img :src="user_detail.avatar" alt="" />
                </div>
                <div class="custom-info">
                  <div class="info-name">{{ user_detail.nick_name }}</div>
                  <div class="info-address">
                    {{ user_detail.address }} {{ user_detail.sex_text }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="account" label="互动记录" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.system_event_text }}</div>
              <div>互动记录（{{ row.num }}次）</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="mobile"
            show-overflow-tooltip
            label="联系方式"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="account_name"
            show-overflow-tooltip
            label="来源账号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="updated_at"
            label="最近互动时间"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="account" label="是否跟进" align="center">
            <template slot-scope="{ row }">
              {{ row.follow_up_text }}
              <el-switch :value="row.follow_up === 2" disabled> </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="opts" label="操作" align="center" width="200">
            <template slot-scope="{ row }">
              <el-button
                size="mini"
                type="text"
                @click="handleOpts('preview', row)"
                >详情</el-button
              >
              <el-button
                size="mini"
                type="text"
                @click="handleOpts('dialogue', row)"
                >发送私信</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="collect" align="center" width="60">
            <template slot-scope="{ row }">
              <i
                v-if="row.collect === 1"
                class="el-icon-star-on"
                style="font-size: 20px; color: #bbb"
                @click="handleCollect(row, 2)"
              ></i>
              <i
                v-else-if="row.collect === 2"
                class="el-icon-star-on"
                style="font-size: 20px; color: #fea76e"
                @click="handleCollect(row, 1)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../../utils/request";

const URL = {
    list: "api/customer/userClueInteract/list",
    collect: "api/customer/userClueInteract/collect",
  },
  QUERY = {
    createTime: [],
    to_user_id: null,
    mobile: null,
  };

// activeIndex = item.index

export default {
  data() {
    return {
      queryParams: {
        ...QUERY,
      },
      tableData: [],
      dialogue: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.isLoading = true;
      const params = {
        collect: 2,
      };
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          ...params,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    resetQuery() {
      this.queryParams = {
        ...QUERY,
      };
      this.getList(1);
    },

    handleOpts(type, row) {
      switch (type) {
        case "dialogue":
          this.dialogue = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        default:
          break;
      }
    },

    // 收藏/取消收藏
    async handleCollect({ id }, collect) {
      await HTTP({
        url: URL.collect,
        method: "post",
        data: {
          id,
          collect,
        },
      });
      this.$message.success("操作成功");
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.custom-view {
  display: flex;
  align-items: center;
  .custom-img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #eee;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .custom-info {
    width: calc(100% - 50px);
    text-align: left;
    .info-name {
      font-size: 15px;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    .info-address {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }
}
</style>
